import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { reportMsg } from "../../utils/tools";

const AdComponent = ({ adSlot, width, height, adId, alias, id, path }) => {
  const location = useLocation();
  const uuid = localStorage.getItem("jlgames-client-id");

  useEffect(() => {
    if (window.googletag) {
      window.googletag.cmd.push(() => {
        window.googletag
          .defineSlot(adSlot, [width, height], adId)
          .addService(window.googletag.pubads());
        window.googletag
          .pubads()
          .addEventListener("impressionViewable", (event) => {
            const slotId = event.slot.getSlotElementId();
            console.log("showed", slotId);
            reportMsg(uuid, id, 0, 8, "showed");
          });

        window.googletag
          .pubads()
          .addEventListener("slotRenderEnded", (event) => {
            console.log("fill or no fill");
            if (event.isEmpty) {
              reportMsg(uuid, id, 0, 8, "nofill");
            } else {
              reportMsg(uuid, id, 0, 8, "fill");
            }
          });

        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().collapseEmptyDivs();
        window.googletag.enableServices();
        window.googletag.display(adId);
      });

      if (location.pathname.includes(path)) {
        reportMsg(uuid, id, 0, 8, "insert");
      }
    }

    return () => {
      if (window.googletag) {
        if (window.googletag?.destroySlots) {
          window.googletag?.destroySlots([adSlot]);
        }
      }
    };
  }, [location, adSlot, adId, width, height, path]); // 监听路由变化

  return (
    <div
      id={adId}
      style={{
        minWidth: width,
        minHeight: height,
        textAlign: "center",
      }}
    ></div>
  );
};

export default AdComponent;
