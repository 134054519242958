import React, { useMemo } from "react";
import GamePlayCard from "../../components/GamePlayCard";
import HotGame from "./HotGame";
import RecentGame from "./RecentGame";
import GameAbout from "./GameAbout";
import Footer from "../footer";
import NavBtn from "../../components/NavBtn";
import { useAppSelector } from "../../store/hooks";
import {
  selectGameList,
  selectHotGames,
  selectRecommendGames,
} from "../home/homeSlice";
import { useParams } from "react-router-dom";
import MoreBtn from "../../components/MoreBtn";
import AdComponent from "../../components/Ad/Banner";
import Anchor from "../../components/Ad/Anchor";

export default function Detail() {
  const { id } = useParams();
  const hotGames = useAppSelector(selectHotGames);
  const recentGames = useAppSelector(selectRecommendGames);
  const allGames = useAppSelector(selectGameList);

  const gameItem = useMemo(() => {
    return allGames.find((item) => item.id === +id);
  }, [allGames, id]);

  return (
    <div className="w-full max-w-[450px] min-w-[320px] mx-auto min-h-screen px-[0.75rem] py-[0.5rem]">
      <NavBtn toPath={"/home"} />
      <Anchor
        adSlot={"/22872686226,23087454935/sentinelgame.com_1018_320x100_1"}
        alias={"anchorbanner"}
        id={38}
        path={"detail"}
      />
      <GamePlayCard {...gameItem} />
      <AdComponent
        alias={"Gamecenterbanner"}
        adSlot={"/22872686226,23087454935/sentinelgame.com_1018_300x250_3"}
        adId={"div-gpt-ad-1729235939140-0'"}
        width={300}
        height={250}
        id={35}
        path={"detail"}
      />
      <div className="w-full my-[1rem]">
        <HotGame list={hotGames} />
      </div>
      <div className="w-full my-[1rem]">
        <RecentGame list={recentGames} />
      </div>
      <AdComponent
        alias={"Gamebottombanner"}
        adSlot={"/22872686226,23087454935/sentinelgame.com_1018_320x100_1"}
        adId={"div-gpt-ad-1727405534807-0"}
        width={320}
        height={100}
        id={36}
        path={"detail"}
      />
      <div className="w-full my-[1rem]">
        <GameAbout name={gameItem.name} desc={gameItem.description} />
      </div>
      <MoreBtn />
      <Footer />
    </div>
  );
}
