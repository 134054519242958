export const HOST =
  process.env.NODE_ENV === "production"
    ? window.location.host
    : "sentinelgame.com";

export function getHotGames(count, gameList, type) {
  if (type === "all") {
    return [...gameList].slice(0, count);
  } else {
    const sortList = [...gameList].sort(
      (a, b) => parseInt(b.playCounts) - parseInt(a.playCounts)
    );
    return sortList.slice(0, count);
  }
}

export function getRecommendGames(count, gameList) {
  let targetList = [...gameList].filter((game) => game.recommended > 0);
  if (targetList.length === 0) {
    const shuffledArray = [...gameList].sort(() => 0.5 - Math.random());
    targetList = shuffledArray.slice(0, 40);
  }
  return targetList.slice(0, count);
}

export function getHadBannerGames(count, gameList) {
  const targetList = [...gameList].filter((game) => game.banner.length > 0);
  const sortList = targetList.sort(
    (a, b) => parseInt(b.playCounts) - parseInt(a.playCounts)
  );
  return sortList.slice(0, count);
}

export function generateUUID() {
  // 生成一个随机的8位16进制字符串
  return Math.random().toString(16).slice(2, 10); // 取随机数的小数部分后8位
}

const reportHost = "https://xdata.jilaihuyu.com/site/event/push";

export function objToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
}

export function reportMsg(uuid, pid, gid, sid, type) {
  const params = {
    event_type: type,
    uuid: uuid,
    adunitid: pid,
    game_id: gid || 0,
    site_id: sid,
  };

  const xhr = new XMLHttpRequest();

  xhr.open("POST", reportHost, true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send(objToQueryString(params));
}
