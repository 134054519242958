import React, { Fragment, useMemo } from "react";
import HeaderBar from "../../components/Header";
import { Link, useLocation } from "react-router-dom";
import Footer from "../footer";
import { useAppSelector } from "../../store/hooks";
import { selectHadBannerGames } from "./homeSlice";
import MoreBtn from "../../components/MoreBtn";
import AdComponent from "../../components/Ad/Banner";
import Anchor from "../../components/Ad/Anchor";

export default function Home() {
  const { search } = useLocation();
  const gameList = useAppSelector(selectHadBannerGames);

  const renderGameList = useMemo(() => {
    return gameList.map((item, index) => {
      if (index === 4) {
        return (
          <Fragment>
            <div
              key={item.id}
              className="w-full rounded-[0.5rem] h-[14.8rem] md:h-[17.6rem] my-[0.5rem] shadow-md overflow-hidden"
            >
              <Link to={`/detail/${item.id}${search}`}>
                <img
                  className="w-full h-full object-cover"
                  src={item.banner}
                  alt={item.name}
                />
              </Link>
            </div>
            <AdComponent
              key={"div-gpt-ad-1729235925236-0"}
              alias={"Homecenterbanner"}
              adSlot={
                "/22872686226,23087454935/sentinelgame.com_1018_300x250_2"
              }
              width={300}
              height={250}
              adId={"div-gpt-ad-1729235925236-0"}
              id={32}
              path={"home"}
            />
          </Fragment>
        );
      }
      return (
        <div
          key={item.id}
          className="w-full rounded-[0.5rem] h-[14.8rem] md:h-[17.6rem] my-[0.5rem] shadow-md overflow-hidden"
        >
          <Link to={`/detail/${item.id}${search}`}>
            <img
              className="w-full h-full object-cover"
              src={item.banner}
              alt={item.name}
            />
          </Link>
        </div>
      );
    });
  }, [gameList]);

  return (
    <div className="w-full max-w-[450px] min-w-[320px] mx-auto min-h-screen px-[0.75rem] py-[0.5rem]">
      <Anchor
        adSlot={"/22872686226,23087454935/sentinelgame.com_1018_320x100_2"}
        alias={"anchorbanner"}
        id={39}
        path={"home"}
      />
      <AdComponent
        adSlot={"/22872686226,23087454935/sentinelgame.com_1018_300x250_1"}
        width={300}
        height={250}
        adId={"div-gpt-ad-1729235911868-0"}
        alias={"Hometopbanner"}
        id={33}
        path={"home"}
      />
      <HeaderBar />
      <div className="w-full mt-[0.5rem]">{renderGameList}</div>
      <MoreBtn />
      <AdComponent
        alias={"Homebottombanner"}
        adSlot={"/22872686226,23087454935/sentinelgame.com_1018_320x50_1"}
        width={320}
        height={50}
        adId={"div-gpt-ad-1729235987745-0"}
        id={34}
        path={"home"}
      />
      <Footer />
    </div>
  );
}
