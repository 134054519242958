import React, { useEffect } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
  Navigate,
} from "react-router-dom";
import Home from "./pages/home";
import Notfound from "./pages/404/Notfound";
import Detail from "./pages/detail";
import Agreements from "./components/Agreements";
import Policy from "./components/Policy";
import About from "./components/About";
import MoreGames from "./pages/more";

// 处理页面切换时，滚轮复位的问题
const ScrollTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const Layout = () => {
  return (
    <>
      <ScrollTop />
      <Outlet />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/home" replace />,
      },
      {
        path: "/home",
        errorElement: <Notfound />,
        element: <Home />,
      },
      {
        path: "/detail/:id",
        element: <Detail />,
      },
      {
        path: "/all",
        element: <MoreGames />,
      },
      {
        path: "/terms",
        element: <Agreements />,
      },
      {
        path: "/policy",
        element: <Policy />,
      },
      {
        path: "/about",
        element: <About />,
      },
    ],
  },
  {
    path: "*",
    element: <Notfound />,
  },
]);

export const MyRouter = () => {
  return <RouterProvider router={router} />;
};
