import React from "react";
import { useNavigate } from "react-router-dom";
export default function NavBtn(props) {
  const { toPath } = props;
  const navigate = useNavigate();

  const handleBack = () => {
    if (toPath) {
      return navigate(toPath);
    }
    return navigate(-1);
  };

  return (
    <div className="w-full">
      <div
        className="w-[4rem] h-[2rem] mb-[0.5rem] cursor-pointer rounded-xl text-[1rem] font-bold border border-solid border-gray-200 shadow-2xl flex items-center justify-center"
        onClick={handleBack}
      >
        Back
      </div>
    </div>
  );
}
