import React from "react";

export default function HeaderBar() {
  return (
    <div className="w-full px-[1rem] rounded bg-orange-300 shadow-lg">
      <p className="m-0 p-0 w-full text-center text-[1.5rem] font-bold text-white">
        SentinelGame
      </p>
    </div>
  );
}
