import React, { useEffect } from "react";
import { MyRouter } from "./Router";
import { generateUUID } from "./utils/tools";

function App() {
  useEffect(() => {
    let uuid = localStorage.getItem("jlgames-client-id");
    if (!uuid) {
      uuid = generateUUID();
      localStorage.setItem("jlgames-client-id", uuid);
    }
  }, []);
  return (
    <div className="w-full min-h-screen bg-gray-100">
      <MyRouter />
    </div>
  );
}

export default App;
