import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function MoreBtn() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const handleToMore = useCallback(() => {
    navigate(`/all${search}`);
  }, []);
  return (
    <div
      className="w-full my-[1rem] px-[1rem] py-[0.5rem] text-[1.5rem] font-bold text-center text-[#fff] bg-orange-300 rounded-[0.5rem] cursor-pointer"
      onClick={handleToMore}
    >
      More Games
    </div>
  );
}
