import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    gameList: [],
    hotGames: [],
    recommendGames: [],
    hadBannerGames: [],
    adConfig: [],
  },
  reducers: {
    initGameList: (state, action) => {
      state.gameList = action.payload;
    },
    initHotGames: (state, action) => {
      state.hotGames = action.payload;
    },
    initRecommendGames: (state, action) => {
      state.recommendGames = action.payload;
    },
    initHadBannerGames: (state, action) => {
      state.hadBannerGames = action.payload;
    },
    initAdConfig: (state, action) => {
      state.adConfig = action.payload;
    },
  },
});

export const {
  initGameList,
  initHotGames,
  initRecommendGames,
  initHadBannerGames,
  initAdConfig,
} = homeSlice.actions;

export const selectGameList = (state) => state.home.gameList;
export const selectHotGames = (state) => state.home.hotGames;
export const selectRecommendGames = (state) => state.home.recommendGames;
export const selectHadBannerGames = (state) => state.home.hadBannerGames;
export const selectAdConfig = (state) => state.home.adConfig;
export default homeSlice.reducer;
