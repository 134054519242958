import React, { useMemo } from "react";
import NavBtn from "../../components/NavBtn";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { selectGameList, selectHadBannerGames } from "../home/homeSlice";
import HotGame from "../detail/HotGame";

export default function MoreGames() {
  const { search } = useLocation();
  const allGames = useAppSelector(selectGameList);
  const gameList = useAppSelector(selectHadBannerGames);

  const renderFirst = useMemo(() => {
    return [...allGames].splice(0, 12).map((item) => {
      return (
        <div
          key={item.id}
          className=" mx-auto rounded-2xl shadow-xl overflow-hidden"
        >
          <Link to={`/detail/${item.id}${search}`}>
            <img
              className="w-full h-full object-cover"
              src={item.icon}
              alt={item.name}
            />
          </Link>
        </div>
      );
    });
  }, [allGames]);

  const hotGames = useMemo(() => {
    return [...gameList].splice(gameList.length - 11, 10).map((item) => {
      return (
        <div
          key={item.id}
          className="w-full rounded-[0.5rem] h-[14.8rem] md:h-[17.6rem] my-[0.5rem] shadow-md overflow-hidden"
        >
          <Link to={`/detail/${item.id}${search}`}>
            <img
              className="w-full h-full object-cover"
              src={item.banner}
              alt={item.name}
            />
          </Link>
        </div>
      );
    });
  }, [gameList]);

  return (
    <div className="w-full max-w-[450px] min-w-[320px] mx-auto min-h-screen px-[0.75rem] py-[0.5rem]">
      <NavBtn toPath={"/home"} />
      <div className="w-full my-[1rem]">
        <div className="w-full bg-white rounded-lg shadow-xl">
          <div className="text-[1.25rem] px-[1rem] py-[0.5rem] font-bold text-left border-solid border border-x-0 border-t-0 border-gray-300">
            Popular Game
          </div>
          <div className="w-full px-[1rem] py-[0.5rem]">
            <div className="w-full py-[1rem] grid grid-cols-3 md:grid-cols-4 gap-2 items-center justify-center">
              {renderFirst}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full my-[1rem]">{hotGames}</div>
      <div className="w-full my-[1rem]">
        <HotGame list={[...allGames].splice(12)} />
      </div>
    </div>
  );
}
