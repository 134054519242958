import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

export default function RecentGame(props) {
  const { list } = props;
  const { search } = useLocation();

  const renderList = useMemo(() => {
    return list.map((item) => {
      return (
        <div
          key={item.id}
          className=" mx-auto w-[3.125rem] h-[3.125rem] rounded-2xl shadow-xl overflow-hidden"
        >
          <Link to={`/detail/${item.id}${search}`}>
            <img
              className="w-full h-full object-cover"
              src={item.icon}
              alt={item.name}
            />
          </Link>
        </div>
      );
    });
  }, [list]);

  return (
    <div className="w-full bg-white rounded-lg shadow-xl">
      <div className="w-full px-[1rem] py-[0.5rem]">
        <div className="text-[1.25rem] py-[0.5rem] font-bold text-left border-solid border border-x-0 border-t-0 border-gray-300">
          Recent Game
        </div>
        <div className="w-full py-[1rem] grid grid-cols-5 md:grid-cols-6 gap-2 items-center justify-center">
          {renderList}
        </div>
      </div>
    </div>
  );
}
