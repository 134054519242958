import React from "react";

export default function GameAbout({ name, desc }) {
  return (
    <div className="w-full bg-white rounded-lg shadow-xl px-[1rem] py-[0.5rem]">
      <div className="pb-[0.5rem] text-[1.25rem] font-bold border border-x-0 border-t-0 border-b-[1px] border-[#ddd]">{`How To Play ${name}`}</div>
      <p className="m-0 p-0 my-[1rem] text-base font-normal">{desc}</p>
    </div>
  );
}
