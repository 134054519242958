const request = async (requestParams) => {
  const { url, timeout = 20000, options = {} } = requestParams;
  const controller = new AbortController();
  const signal = controller.signal;

  const timeoutId = setTimeout(() => {
    controller.abort();
  }, timeout);
  return new Promise((resolve, reject) => {
    options.signal = signal;
    fetch(url, {
      ...options,
      // mode: "no-cors",
      body:
        options?.type === "form" ? options.body : JSON.stringify(options.body),
      headers: {
        Authorization: "",
        ...options.headers,
      },
    })
      .then((response) => {
        clearTimeout(timeoutId);
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        } else {
          return reject(response?.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default request;
